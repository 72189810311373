
  function show_sidebar() {
    var element = document.getElementById("sidebar1");
    element.classList.toggle("active");
 }
 function hide_sidebar()
 {
    var element2 = document.getElementById("sidebar1");
    element2.classList.remove("active");
 }
 function menubar(x) {
   x.classList.toggle("change");
 }

 $(document).ready(function () {
   $("#sidebar1").mCustomScrollbar({
       theme: "minimal"
   });


   $('#sidebarCollapse').on('click', function () {
       // open sidebar
       $('#sidebar1').addClass('active');
       // fade in the overlay
       $('.overlay').addClass('active');
       $('.collapse.in').toggleClass('in');
       $('a[aria-expanded=true]').attr('aria-expanded', 'false');
   });
});